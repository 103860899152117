



















































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { Api } from "@/models/class/api.class";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { Messages } from "@/models/enums/messages.enum";
import {
  CustomDataListAssetAdditions,
  DataListAssetBook,
  RequestPostingAsset,
  RequestTemplateDownload,
  ResponseListAssetAdditions
} from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";
import { currencyFormat } from "@/validator/globalvalidator";
import { UploadFile } from "ant-design-vue/types/upload";
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  components: {
    ListPostingAsset: () => import("./ListPostingAsset.vue"),
    ModalFindDataAssetPosting: () => import("./ModalFindDataAssetPosting.vue"),
  },
  data() {
    this.getListAssetBook = debounceProcess(this.getListAssetBook, 200);
    return {
      Api,
      paramsListPostingAsset: {
        page: 0,
        limit: 10,
        sorts: "createdDate:desc",
        search: `status~Posted`,
      } as RequestQueryParamsModel,
      paramsPostingAsset: {
        page: 0,
        limit: 10,
        sorts: "createdDate:desc",
        search: `status~New_OR_status~Ready_to_Post`,
      } as RequestQueryParamsModel,
      visibleModalSearch: false as boolean,
      valueSearch: "" as string,
      dataListBook: [] as DataListAssetBook[],
      loadingPosting: false as boolean,
      loadingPostingAll: false as boolean,
      loadingListAssetBook: false as boolean,
      dataForPosting: {} as RequestPostingAsset,
      dataBlobUrl: "" as string,
      fileList: [] as UploadFile[],
      loading: false as boolean,
      loadingPostingAsset: false as boolean,
      pathFile: "" as string,
      totalElements: 1 as number,
      limit: 10 as number,
      direction: "" as string,
      dataFilter: [] as string[],
      sort: "" as string,
      search: "" as string,
      page: 1 as number,
      selectedRowKeys: [] as number[],
      currentTab: "Import Assets",
      tabPane: ["Import Assets", "Posting Assets"],
      checkbox: true,
      headers: {
        authorization: "Bearer " + this.$store.state.access_token,
        "Content-Type": "multipart/form-data",
      },
      form: this.$form.createForm(this, { name: "additionsImport" }),
      formRules: {
        download: {
          label: "lbl_download_format_add_asset",
          name: "download",
          placeholder: "lbl_download_format_add_asset_placeholder",
          decorator: [
            "download",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        book: {
          label: "lbl_book_name",
          name: "book",
          placeholder: "lbl_book_name_placeholder",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      formRulesUpload: {
        upload: {
          label: "lbl_upload",
          name: "upload",
          placeholder: "Insert your Upload",
          decorator: [
            "upload",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      dataSource: [] as CustomDataListAssetAdditions[],
      dataSourceListPostingAsset: [] as CustomDataListAssetAdditions[],
      responseListPostingAsset: {} as ResponseListAssetAdditions,
      columnsTable: [
        {
          title: this.$t("lbl_book"),
          dataIndex: "assetBook",
          key: "assetBook",
          sorter: true,
          width: "150px",
        },
        {
          title: this.$t("lbl_category"),
          dataIndex: "parseAssetCategory",
          key: "parseAssetCategory",
          scopedSlots: { customRender: "isNull" },
          width: "150px",
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          scopedSlots: { customRender: "isNull" },
          width: "220px",
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          scopedSlots: { customRender: "isNull" },
          width: "220px",
        },
        {
          title: this.$t("lbl_location"),
          dataIndex: "location",
          key: "location",
          scopedSlots: { customRender: "isNull" },
          width: "220px",
        },
        {
          title: this.$t("lbl_qty"),
          dataIndex: "unit",
          key: "unit",
          scopedSlots: { customRender: "isNull" },
          width: "150px",
        },
        {
          title: this.$t("lbl_life_months"),
          dataIndex: "lifeMonths",
          key: "lifeMonths",
          scopedSlots: { customRender: "isNull" },
          width: "150px",
        },
        {
          title: this.$t("lbl_acquisition_date"),
          dataIndex: "acquisitionDate",
          key: "acquisitionDate",
          scopedSlots: { customRender: "isNull" },
          width: "220px",
        },
        {
          title: this.$t("lbl_asset_cost"),
          dataIndex: "customAssetCost",
          key: "customAssetCost",
          scopedSlots: { customRender: "isNull" },
          width: "150px",
        },
        {
          title: this.$t("lbl_source"),
          dataIndex: "source",
          key: "source",
          scopedSlots: { customRender: "isNull" },
          width: "150px",
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          scopedSlots: { customRender: "isNull" },
          width: "150px",
        },
        {
          title: this.$t("lbl_receive_number"),
          dataIndex: "receiveNumber",
          key: "receiveNumber",
          scopedSlots: { customRender: "isNull" },
          width: "160px",
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "state",
          key: "state",
          scopedSlots: { customRender: "isNull" },
          width: "170px",
        },
        {
          title: this.$t("lbl_journal_number"),
          dataIndex: "journalNo",
          key: "journalNo",
          width: "150px",
          scopedSlots: { customRender: "clickColumn" },
        },
        {
          title: this.$root.$t("lbl_action").toString(),
          dataIndex: "operation",
          width: "120px",
          align: "center",
          scopedSlots: { customRender: "operation" },
          button: ["update"],
        },
      ],
    };
  },
  methods: {
    beforeUpload(file) {
      const isLt5M = file.size;
      if (isLt5M >= 5242880) {
        this.$notification.error({
          description: "File must smaller than 5MB!",
          message: "Error",
          duration: 30,
        });
      }
      return isLt5M;
    },
    handleDownload() {
      this.form.validateFields((err, res) => {
        if (err) return;

        const payload = {
          bookId: res.book,
          file: {
            bucketName: "asset",
            objectName: res.download,
          },
        } as RequestTemplateDownload;

        this.loadingDownload = true;
        assetsServices
          .postDownloadTemplateAssetAdditions(payload)
          .then(data => {
            if (data) {
              const url = window.URL.createObjectURL(new Blob([data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "data.xlsx"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .finally(() => (this.loadingDownload = false));
      });
    },
    handlePaginationListPostingAssset(params) {
      this.paramsListPostingAsset = {
        ...this.paramsListPostingAsset,
        ...params,
      };
      this.getListAssetAdditionsListPostingAsset();
    },
    handleFindDataModal(searchParams) {
      if (this.currentTab === "Posting Assets") {
        // this.params
        this.paramsPostingAsset = searchParams;
        this.getListAssetAdditions();
        this.visibleModalSearch = false;
      } else if (this.currentTab === "List of Posted Assets") {
        this.paramsListPostingAsset = searchParams;
        this.getListAssetAdditionsListPostingAsset();
        this.visibleModalSearch = false;
      }
    },
    handleVisible(value: boolean) {
      this.visibleModalSearch = value;
    },
    getListAssetBook(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        search: `type~commercial_AND_active~true`,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
      this.loadingListAssetBook = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loadingListAssetBook = false));
    },
    handlePosting() {
      if (this.dataForPosting.secureIds.length > 0) {
        this.loadingPosting = true;
        assetsServices
          .postingAssetAdditions(this.dataForPosting)
          .then(response => {
            this.$notification.success({
              description: `Asset Number : ${response.assetsNo}`,
              message: "Success",
              duration: 30,
            });
            this.$notification.success({
              description: `Unit Code : ${response.unitsCode}`,
              message: "Success",
              duration: 30,
            });
            this.getListAssetAdditions();
            this.$notification.success({
              description: "Success Posting",
              message: "Success",
              duration: 30,
            });
            this.selectedRowKeys = [];
          })
          .catch(() =>
            this.$notification.error({
              description: Messages.CREATE_FAIL,
              message: "Error",
              duration: 30,
            })
          )
          .finally(() => (this.loadingPosting = false));
      } else {
        this.$notification.error({
          message: "Error",
          description: "Please select data for posting",
        });
      }
    },
    handlePostingAll() {
      this.$confirm({
        title: this.$t("lbl_confirm_posting_all"),
        onOk: () => {
          this.loadingPostingAll = true;
          assetsServices
            .postingAllAssetAdditions()
            .then(() => {
              this.$notification.success({
                description: "Success Posting",
                message: "Success",
                duration: 30,
              });
              this.getListAssetAdditions();
              this.selectedRowKeys = [];
            })
            .finally(() => (this.loadingPostingAll = false));
        },
        onCancel() {
          return;
        },
      });
    },
    handleDownloadUpload() {
      if (this.dataBlobUrl) {
        const link = document.createElement("a");
        link.href = this.dataBlobUrl;
        link.setAttribute("download", "data.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        this.$notification.error({
          message: "Error",
          description: "Please Upload Document First",
        });
      }
    },
    onSorterChange(response): void {
      if (response.sort.order) {
        this.direction = response.sort.order === "ascend" ? "asc" : "desc";
        this.sort = `${response.sort.field}:${this.direction}`;
      }
      this.dataFilter = response?.filters.state || [];
      this.handleQuerySearch();
      this.getListAssetAdditions();
    },
    getDataAssetLocation(dataLocation): string | null {
      return dataLocation
        ? `${dataLocation.warehouse.branchWarehouse.name} - ${dataLocation.warehouse.name} - ${dataLocation.name}`
        : null;
    },
    loopDataAssetCategory(dataAssetCategory): {
      first: string;
      second: string;
    } {
      let tempSegment = {
        first: "",
        second: "",
      };
      dataAssetCategory.segments.forEach((dataCategory, indexCategory) => {
        if (dataCategory.first) {
          tempSegment.first += dataCategory.first;
        }
        if (dataCategory.second) {
          tempSegment.second += dataCategory.second;
        }
        if (indexCategory % 2 === 0) {
          tempSegment.first += ".";
        }
      });
      return tempSegment;
    },
    getListAssetAdditions() {
      // let params = {
      //   page: this.page - 1,
      //   limit: 10,
      //   search: `status~New_OR_status~Ready_to_Post`,
      //   sorts: 'createdDate:desc',
      //   categoryId: this.valueSearch,
      //   receiveNo: this.valueSearch,
      // } as RequestQueryParamsModel
      // if(this.search) params.search = this.search
      // if (this.direction) params.sorts = `${this.sort},${params.sorts}`;
      const params: RequestQueryParamsModel = {
        ...this.paramsPostingAsset,
        page:
          this.paramsPostingAsset.page === 0
            ? this.paramsPostingAsset.page
            : (this.paramsPostingAsset.page as number) - 1,
        limit: this.paramsPostingAsset.limit,
      };
      if (this.direction) params.sorts = `${this.sort},${params.sorts}`;
      this.loading = true;
      assetsServices
        .listAssetAdditions(params)
        .then(response => {
          this.totalElements = response.totalElements;
          let newData = [] as CustomDataListAssetAdditions[];

          response.data.forEach((data, index) => {
            if (data.acquisitionDate) {
              data.acquisitionDate = moment(data.acquisitionDate).format(
                "DD-MM-yyyy"
              );
            }
            newData = [
              ...newData,
              {
                ...data,
                customAssetCategory: null,
                key: index,
                location: null,
                customAssetCost: null,
                parseAssetCategory: data.assetCategory?.id
                  .split(".")
                  .map(dataMapString => {
                    return dataMapString.replace(/\b[a-z](?=[a-z]{2})/g, e =>
                      e.toUpperCase()
                    );
                  })
                  .join("."),
                journalId: "",
                journalNo: "",
              },
            ];
            // parse data category
            if (data.assetCategory) {
              let tempSegment = this.loopDataAssetCategory(data.assetCategory);
              newData[
                index
              ].customAssetCategory = `${tempSegment.first}.${tempSegment.second}`;
            }
            // parse data location
            newData[index].location = this.getDataAssetLocation(
              data.assetLocation
            );

            // custom assetcost to IDR
            newData[index].customAssetCost = currencyFormat(data.assetCost);
          });
          this.dataSource = newData;
        })
        .finally(() => (this.loading = false));
    },
    handleClickColumn(record, objColumnNameValue: { column; value }): void {
      if (objColumnNameValue.column === "journalNo") {
        this.$router.push("/generaljournal/journal/detail/" + record.journalId);
      }
    },
    getListAssetAdditionsListPostingAsset() {
      this.loadingPostingAsset = true;
      const params: RequestQueryParamsModel = {
        ...this.paramsListPostingAsset,
        page:
          this.paramsListPostingAsset.page === 0
            ? this.paramsListPostingAsset.page
            : (this.paramsListPostingAsset.page as number) - 1,
        limit: this.paramsListPostingAsset.limit,
      };
      assetsServices
        .listAssetAdditions(params)
        .then(response => {
          this.responseListPostingAsset = response;
        })
        .finally(() => (this.loadingPostingAsset = false));
    },
    customRequest(options) {
      const data = new FormData();
      data.append("file", options.file);
      assetsServices
        .createUploadAssetAddtions(data)
        .then(response => {
          this.getListAssetAdditions();
          options.onSuccess();
          // new response
          if (response.errorExcelUrl) {
            this.$notification.error({
              description: response.message,
              message: "Error",
              duration: 30,
            });
            const link = document.createElement("a");
            link.href = response.errorExcelUrl;
            link.target = "_blank";
            link.setAttribute("download", "data.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          } else {
            this.$notification.success({
              description: response.message,
              message: "Success",
              duration: 30,
            });
          }
        })
        .catch(err => {
          options.onError(err, options);
        });
    },
    ResetFilter(): void {
      this.direction = "";
      this.search = "";
      this.valueSearch = "";
      this.getListAssetAdditions();
    },
    reponseSearchInput(value) {
      if (value) {
        this.search = `description~*${value}*_OR_serialNumber~*${value}*_OR_unitCode~*${value}*_OR_invoiceNumber~*${value}*_OR_purchaseOrderNumber~*${value}*_OR_warranty~*${value}*_OR_model~*${value}*_OR_assetLocation.warehouse.name~*${value}*_OR_assetLocation.name~*${value}*_OR_assetLocation.warehouse.branchWarehouse.name~*${value}*`;
        this.valueSearch = value;
      } else {
        this.search = "";
        this.valueSearch = value;
      }
      this.page = 1;
      this.handleQuerySearch();
      this.getListAssetAdditions();
    },
    handleQuerySearch() {
      const tempSearch = `description~*${this.valueSearch}*_OR_serialNumber~*${this.valueSearch}*_OR_unitCode~*${this.valueSearch}*_OR_invoiceNumber~*${this.valueSearch}*_OR_purchaseOrderNumber~*${this.valueSearch}*_OR_warranty~*${this.valueSearch}*_OR_model~*${this.valueSearch}*_OR_assetLocation.warehouse.name~*${this.valueSearch}*_OR_assetLocation.name~*${this.valueSearch}*_OR_assetLocation.warehouse.branchWarehouse.name~*${this.valueSearch}*`;
      if (this.valueSearch) {
        let newSearch = "";
        if (this.dataFilter.length > 0) {
          this.dataFilter.forEach((dataForeach, index) => {
            if (index !== 0) {
              newSearch += "_OR_" + tempSearch + `_AND_status~*${dataForeach}*`;
            } else if (index === 0) {
              newSearch = tempSearch + `_AND_status~*${dataForeach}*`;
            }
          });
          this.search = newSearch;
        } else {
          newSearch =
            tempSearch +
            "_AND_status~*New*_OR_" +
            tempSearch +
            "_AND_status~*Ready_to_Post*";
        }
      } else {
        if (this.dataFilter.length > 0) {
          let newSearch = "";
          this.dataFilter.forEach((dataForeach, index) => {
            if (index !== 0) {
              newSearch += `_OR_status~*${dataForeach}*`;
            } else if (index === 0) {
              newSearch = `status~*${dataForeach}*`;
            }
          });
          this.search = newSearch;
        } else {
          this.search = "status~*New*_OR_status~*Ready_to_Post*";
        }
      }
    },
    onSelectChange(selectedRowKeys: number[]) {
      let idForPosting = [] as string[];
      selectedRowKeys.forEach(key => {
        idForPosting.push(this.dataSource[key].id);
      });
      this.dataForPosting.secureIds = idForPosting;
      this.selectedRowKeys = selectedRowKeys;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.paramsPostingAsset.limit = response.size;
      this.paramsPostingAsset.page = 1;
      this.getListAssetAdditions();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.paramsPostingAsset.page = response.page;
      this.getListAssetAdditions();
    },
    reponseEditTable(response) {
      this.$router.push(`/additions/single/update/${response.data.id}`);
    },
    handleAddToAsset() {
      this.$router.push("/additions/create");
    },
    changeMenu(value) {
      this.currentTab = value;
    },
    handleChange(info) {
      let fileList = [...info.fileList];
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. Read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
      if (info.file.status === "done") {
        this.pathFile = info.file.response.url;
        this.$notification.success({
          description: `${info.file.name} file uploaded successfully`,
          message: "Success",
          duration: 30,
        });
      } else if (info.file.status === "error") {
        this.$notification.error({
          description: `${info.file.name} file upload failed.`,
          message: "Error",
          duration: 30,
        });
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.getListAssetBook("");
    this.getListAssetAdditions();
    this.getListAssetAdditionsListPostingAsset();
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
